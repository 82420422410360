@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,600&display=swap');
body{
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background: var(--bg);
  color: var(--black);
  transition: all 3ms ease;
  -moz-scroll-behavior:smooth;
  -webkit-scroll-behavior:smooth;
  -ms-scroll-behavior:smooth;
  scroll-behavior: smooth;
}