.cFooterWrapper{
    width: 100%;
    margin-top: 5rem;
}
.cFooterWrapper>hr{
    height: 1px;
    width: 100%;
    border: none;
    background: white;
    margin-top: 1rem;
}
.cFooter{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.cLogo{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}
.cLogo>img{
    width: 2.5rem;
    height: 2.5rem;
}
.cLogo>span{
    font-weight: 600;
    font-size: 1rem;
}
.block{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 12rem;
}
.detail{
    display: flex;
    flex-direction: column;
    width: inherit;
    font-style: italic;
    font-size: .7rem;
    gap: 1rem;
}
.detail>span:nth-of-type(1){
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;

}
.pngLine:hover{
    cursor: pointer;
}
.pngLine{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.icon{
    width: 25px;
}
.copyright{
    display: flex;
    align-items: center;
    justify-content:  center;
    font-size: .7rem;
    flex-direction: column;
    
}
@media screen and ( max-width:768px) {
    .cFooter{
        gap: 2rem;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 1.7rem;
    }
}
@media screen and ( max-width:640px) {
    .cFooter{
        justify-content: center;
    }
    .cLogo{
        display: none;
    }
}