.container{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    padding: 0rem 2rem;
}
.wrapper{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: baseline;
}
.wrapper>img{
    width: 30rem;
    position: absolute;
    bottom: 0;
}
.blueCircle{
    width: 30rem;
    height: 30rem;
    z-index: -99;
    bottom: 0;
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(135.74deg,#0bb5ff 14.09%,#6dd3fe 83.81%);
}
.cart2{
    position:absolute;
    right: 5%;
    bottom: 25%;
    display: flex;
    gap: 1rem;
    align-items: center;
}
.cart2>svg{
    background: white;
    border-radius: 50%;
    padding: 10px;
    width: 30px;
    height: 30px;
    border: 6px solid var(--black);
}
.signup{
    display: flex;
    background: white;
    padding: 10px;
    border-radius: 15px;
    align-items: center;
    box-shadow: var(--shadow1);
    font-size: 0.8rem;
}
.signup>:first-child{
    display: block;
    width: 6rem;
}
.signup>:nth-child(2){
    border-radius: 50%;
    border: 1px solid skyblue;
    display: flex;
    padding: 5px;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
}


.h_side{
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.text1{
    text-transform: uppercase;
    font-size: 1.5rem;
    width: min-content;
    display: block;
    font-weight: 600;
}
.text2{
    display: flex;
    flex-direction: column;
    color: black;
    width: min-content;
}
.text2>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}
.text2>:nth-child(2){
    display: block;
}
.traffic{
    display: flex;
    flex-direction: column;
    text-align: right;
}
.traffic>:first-child{
    font-weight: 600;
    font-size: 2.5rem;
}
.customers{
    display: flex;
    flex-direction: column;
    text-align: right;
}
.customers>:first-child{
    font-weight: 600;
    font-size: 2.5rem;
}
@media screen and ( max-width:856px) {
    .text1,.text2>span:nth-of-type(2){
        font-size: .9rem;
        text-align: justify;
    }
    .text2>span:nth-of-type(1),
    .traffic>span:nth-child(1),
    .customers>span:nth-child(1){
        text-align: left;
        font-size: 1.5rem;
    }
    .blueCircle{
        position: absolute;
        width: 70%;
        height: 100%;
    }
    .wrapper>img{
        width: 70%;
        height: 100%;
    }
    .container{
        grid-template-areas: 
        'left center center'
        'right right rihght'
        ;
    }
    .container>:first-child{
        grid-area: left;
        grid-template-rows: none;
        gap: 8rem;

    }
    .container>:nth-child(2){
        grid-area: center;
    }
    .container>:nth-child(3){
        display: flex;
        grid-area: right;
        justify-content: space-around;
        margin: 2rem 0;
    }
}
@media screen and ( max-width:640px) {
    .blueCircle{
        top: 0;
        height: 72%;
        width: 16rem;
    }
    .wrapper>img{
        top: 3rem;
        height: 15rem;
        width: 12rem;
    }
    .cart2{
      transform: scale(.7); 
      right: -2rem;
      bottom: 0; 
    }

}