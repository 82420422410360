.container{
    padding: 1.5rem;
    display: flex;
}
.logo{
    display: flex;
    align-items: center;
    flex: 1;
    gap: 0.5rem;
}
.logo>img{
    width: 2.5rem;
    height: 2.5rem;
}
.logo>span{
    font-weight: 500;
}
.right{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}
.menu{
    gap: 2rem;
    list-style: none;
    display: flex;
    align-items: center;
    font-weight: 500;
}
.menu>li:hover{
    color: #fe956f;
    cursor: pointer;
}
.search{
    width: 7rem;
    height: 30%;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;

}
.cart{
    width: 1.5rem;
    height: 1.5rem;
}
.bars{
    display: none;
}
@media  screen and( max-width:856px) {
    .right{
        gap:1rem;
    }
    .search{
        display: none;
    }
}
@media screen and ( max-width: 640px) {
    .right{
        position: absolute;
        right: 2rem;
        padding: .8rem;
        z-index: 9999;
        border-radius: 5px;
        color: var(--black);
        flex-direction: column;
        background: white;

    }
    .menu{
        flex-direction: column;
        margin-left: -2rem;
        display: none;
    }
    .bars{
        display: block;
    }
    .search{
        display: none;
    }
}

